import CRUDService from '@/services/hrsg/CRUDService';

export class AssessmentService extends CRUDService {
  constructor () {
    super('reviews.assessments');
  }

  async getQuestionnaire () {
    const response = await this.request({
      endpoint: `${this.endpoint}.questionnaire`,
      pathParams: this.getPathvars()
    });

    return response?.payload?.data ?? [];
  }

  async saveResponse (data) {
    const response = await this.request({
      endpoint: `${this.endpoint}.save-response`,
      pathParams: this.getPathvars(),
      data
    });

    return response?.payload?.data ?? [];
  }

  async submitRating () {
    const response = await this.request({
      endpoint: `${this.endpoint}.submit`,
      pathParams: this.getPathvars()
    });

    return response?.payload?.data ?? [];
  }

  async getResults () {
    const response = await this.request({
      endpoint: `${this.endpoint}.results`,
      pathParams: this.getPathvars()
    });

    return response?.payload?.data ?? [];
  }

  async shareResults (pathParams = {}) {
    return await this.request({
      endpoint: `${this.endpoint}.share-results`,
      pathParams
    });
  }

  async changeJob (pathParams = {}, data) {
    return await this.request({
      endpoint: `${this.endpoint}.change-job`,
      pathParams,
      data
    });
  }

  async cancel (data) {
    const response = await this.request({
      endpoint: `${this.endpoint}.cancel`,
      pathParams: this.getPathvars(),
      data
    });

    return response?.payload?.data ?? [];
  }
}

export default new AssessmentService();
